import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'hotels',
    loadChildren: () => import('./pages/hotels/hotels.module').then( m => m.HotelsPageModule)
  },
  {
    path: 'revenue',
    loadChildren: () => import('./pages/revenue/revenue.module').then( m => m.RevenuePageModule)
  },
  {
    path: 'entrance',
    loadChildren: () => import('./pages/entrance/entrance.module').then( m => m.EntrancePageModule)
  },
  {
    path: 'rooms',
    loadChildren: () => import('./pages/rooms/rooms.module').then( m => m.RoomsPageModule)
  },
  {
    path: 'fb',
    loadChildren: () => import('./pages/fb/fb.module').then( m => m.FbPageModule)
  },
  {
    path: 'ood',
    loadChildren: () => import('./pages/ood/ood.module').then( m => m.OodPageModule)
  },
  {
    path: 'tenants',
    loadChildren: () => import('./pages/tenants/tenants.module').then( m => m.TenantsPageModule)
  },
  {
    path: 'revenuecalendar',
    loadChildren: () => import('./pages/revenuecalendar/revenuecalendar.module').then( m => m.RevenuecalendarPageModule)
  },
  {
    path: 'overview',
    loadChildren: () => import('./pages/overview/overview.module').then( m => m.OverviewPageModule)
  },
  {
    path: 'expense',
    loadChildren: () => import('./pages/expense/expense.module').then( m => m.ExpensePageModule)
  },
  {
    path: 'payroll',
    loadChildren: () => import('./pages/payroll/payroll.module').then( m => m.PayrollPageModule)
  },
  {
    path: 'agency',
    loadChildren: () => import('./pages/agency/agency.module').then( m => m.AgencyPageModule)
  },
  {
    path: 'karavansaray',
    loadChildren: () => import('./pages/karavansaray/karavansaray.module').then( m => m.KaravansarayPageModule)
  },
  {
    path: 'karavansarayhome',
    loadChildren: () => import('./pages/karavansarayhome/karavansarayhome.module').then( m => m.KaravansarayhomePageModule)
  },
  {
    path: 'aktau',
    loadChildren: () => import('./pages/aktau/aktau.module').then( m => m.AktauPageModule)
  },
  {
    path: 'main',
    loadChildren: () => import('./pages/main/main.module').then( m => m.MainPageModule)
  },
  {
    path: 'karavansarayoverview',
    loadChildren: () => import('./pages/karavansarayoverview/karavansarayoverview.module').then( m => m.KaravansarayoverviewPageModule)
  },
  {
    path: 'karavansarayrevenue',
    loadChildren: () => import('./pages/karavansarayrevenue/karavansarayrevenue.module').then( m => m.KaravansarayrevenuePageModule)
  },
  {
    path: 'karavansarayentrance',
    loadChildren: () => import('./pages/karavansarayentrance/karavansarayentrance.module').then( m => m.KaravansarayentrancePageModule)
  },
  {
    path: 'karavansarayfb',
    loadChildren: () => import('./pages/karavansarayfb/karavansarayfb.module').then( m => m.KaravansarayfbPageModule)
  },
  {
    path: 'karavansarayood',
    loadChildren: () => import('./pages/karavansarayood/karavansarayood.module').then( m => m.KaravansarayoodPageModule)
  },
  {
    path: 'karavansarayrevenuecalendar',
    loadChildren: () => import('./pages/karavansarayrevenuecalendar/karavansarayrevenuecalendar.module').then( m => m.KaravansarayrevenuecalendarPageModule)
  },
  {
    path: 'karavansarayunits',
    loadChildren: () => import('./pages/karavansarayunits/karavansarayunits.module').then( m => m.KaravansarayunitsPageModule)
  },
  {
    path: 'kazakistan',
    loadChildren: () => import('./pages/kazakistan/kazakistan.module').then( m => m.KazakistanPageModule)
  },
  {
    path: 'aktauhome',
    loadChildren: () => import('./pages/aktauhome/aktauhome.module').then( m => m.AktauhomePageModule)
  },
  {
    path: 'aktauoverview',
    loadChildren: () => import('./pages/aktauoverview/aktauoverview.module').then( m => m.AktauoverviewPageModule)
  },
  {
    path: 'aktaurevenue',
    loadChildren: () => import('./pages/aktaurevenue/aktaurevenue.module').then( m => m.AktaurevenuePageModule)
  },
  {
    path: 'aktaufb',
    loadChildren: () => import('./pages/aktaufb/aktaufb.module').then( m => m.AktaufbPageModule)
  },
  {
    path: 'aktaurooms',
    loadChildren: () => import('./pages/aktaurooms/aktaurooms.module').then( m => m.AktauroomsPageModule)
  },
  {
    path: 'aktauood',
    loadChildren: () => import('./pages/aktauood/aktauood.module').then( m => m.AktauoodPageModule)
  },
  {
    path: 'karavansarayexpense',
    loadChildren: () => import('./pages/karavansarayexpense/karavansarayexpense.module').then( m => m.KaravansarayexpensePageModule)
  },
  {
    path: 'aktauexpense',
    loadChildren: () => import('./pages/aktauexpense/aktauexpense.module').then( m => m.AktauexpensePageModule)
  },
  {
    path: 'aktaurevenuecalendar',
    loadChildren: () => import('./pages/aktaurevenuecalendar/aktaurevenuecalendar.module').then( m => m.AktaurevenuecalendarPageModule)
  },
  {
    path: 'aktaupayroll',
    loadChildren: () => import('./pages/aktaupayroll/aktaupayroll.module').then( m => m.AktaupayrollPageModule)
  },
  {
    path: 'karavansaraypayroll',
    loadChildren: () => import('./pages/karavansaraypayroll/karavansaraypayroll.module').then( m => m.KaravansaraypayrollPageModule)
  },
  {
    path: 'usercheck',
    loadChildren: () => import('./pages/usercheck/usercheck.module').then( m => m.UsercheckPageModule)
  },
  {
    path: 'tetysblu',
    loadChildren: () => import('./pages/tetysblu/tetysblu.module').then( m => m.TetysbluPageModule)
  },
  {
    path: 'tetysbluoverview',
    loadChildren: () => import('./pages/tetysbluoverview/tetysbluoverview.module').then( m => m.TetysbluoverviewPageModule)
  },
  {
    path: 'tetysbluexpense',
    loadChildren: () => import('./pages/tetysbluexpense/tetysbluexpense.module').then( m => m.TetysbluexpensePageModule)
  },
  {
    path: 'tetysblupayroll',
    loadChildren: () => import('./pages/tetysblupayroll/tetysblupayroll.module').then( m => m.TetysblupayrollPageModule)
  },
  {
    path: 'tetysblurevenue',
    loadChildren: () => import('./pages/tetysblurevenue/tetysblurevenue.module').then( m => m.TetysblurevenuePageModule)
  },
  {
    path: 'tetysbluentrance',
    loadChildren: () => import('./pages/tetysbluentrance/tetysbluentrance.module').then( m => m.TetysbluentrancePageModule)
  },
  {
    path: 'tetysblufb',
    loadChildren: () => import('./pages/tetysblufb/tetysblufb.module').then( m => m.TetysblufbPageModule)
  },
  {
    path: 'tetysbluood',
    loadChildren: () => import('./pages/tetysbluood/tetysbluood.module').then( m => m.TetysbluoodPageModule)
  },
  {
    path: 'tetysblurevenuecalendar',
    loadChildren: () => import('./pages/tetysblurevenuecalendar/tetysblurevenuecalendar.module').then( m => m.TetysblurevenuecalendarPageModule)
  },
  {
    path: 'pdfreports',
    loadChildren: () => import('./pages/pdfreports/pdfreports.module').then( m => m.PdfreportsPageModule)
  },
  {
    path: 'karavansarayrooms',
    loadChildren: () => import('./pages/karavansarayrooms/karavansarayrooms.module').then( m => m.KaravansarayroomsPageModule)
  },
  {
    path: 'pdfreportkaravansaray',
    loadChildren: () => import('./pages/pdfreportkaravansaray/pdfreportkaravansaray.module').then( m => m.PdfreportkaravansarayPageModule)
  },
  {
    path: 'hotelnew',
    loadChildren: () => import('./pages/hotelnew/hotelnew.module').then( m => m.HotelnewPageModule)
  },
  {
    path: 'hotelspremium',
    loadChildren: () => import('./pages/hotelspremium/hotelspremium.module').then( m => m.HotelspremiumPageModule)
  },
  {
    path: 'hotelsthemeking',
    loadChildren: () => import('./pages/hotelsthemeking/hotelsthemeking.module').then( m => m.HotelsthemekingPageModule)
  },
  {
    path: 'tetysblutenants',
    loadChildren: () => import('./pages/tetysblutenants/tetysblutenants.module').then( m => m.TetysblutenantsPageModule)
  },
  {
    path: 'swissotel',
    loadChildren: () => import('./pages/swissotel/swissotel.module').then( m => m.SwissotelPageModule)
  },
  {
    path: 'swissoteloverview',
    loadChildren: () => import('./pages/swissoteloverview/swissoteloverview.module').then( m => m.SwissoteloverviewPageModule)
  },
  {
    path: 'swissotelrevenue',
    loadChildren: () => import('./pages/swissotelrevenue/swissotelrevenue.module').then( m => m.SwissotelrevenuePageModule)
  },
  {
    path: 'swissotelexpense',
    loadChildren: () => import('./pages/swissotelexpense/swissotelexpense.module').then( m => m.SwissotelexpensePageModule)
  },
  {
    path: 'swissotelpayroll',
    loadChildren: () => import('./pages/swissotelpayroll/swissotelpayroll.module').then( m => m.SwissotelpayrollPageModule)
  },
  {
    path: 'swissotelrooms',
    loadChildren: () => import('./pages/swissotelrooms/swissotelrooms.module').then( m => m.SwissotelroomsPageModule)
  },
  {
    path: 'swissotelfb',
    loadChildren: () => import('./pages/swissotelfb/swissotelfb.module').then( m => m.SwissotelfbPageModule)
  },
  {
    path: 'swissotelood',
    loadChildren: () => import('./pages/swissotelood/swissotelood.module').then( m => m.SwissoteloodPageModule)
  },
  {
    path: 'swissotelrevenuecalendar',
    loadChildren: () => import('./pages/swissotelrevenuecalendar/swissotelrevenuecalendar.module').then( m => m.SwissotelrevenuecalendarPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'hotels-demo',
    loadChildren: () => import('./pages/hotels-demo/hotels-demo.module').then( m => m.HotelsDemoPageModule)
  },
  {
    path: 'authorization',
    loadChildren: () => import('./pages/authorization/authorization.module').then( m => m.AuthorizationPageModule)
  },
  {
    path: 'user-detail',
    loadChildren: () => import('./pages/user-detail/user-detail.module').then( m => m.UserDetailPageModule)
  },
  {
    path: 'rixos-hotels',
    loadChildren: () => import('./pages/rixos-hotels/rixos-hotels.module').then( m => m.RixosHotelsPageModule)
  },
  {
    path: 'avm',
    loadChildren: () => import('./pages/avm/avm.module').then( m => m.AvmPageModule)
  },
  {
    path: 'merchandasing',
    loadChildren: () => import('./pages/merchandasing/merchandasing.module').then( m => m.MerchandasingPageModule)
  },
  {
    path: 'wfk',
    loadChildren: () => import('./pages/wfk/wfk.module').then( m => m.WfkPageModule)
  },
  {
    path: 'consolide-reports',
    loadChildren: () => import('./pages/consolide-reports/consolide-reports.module').then( m => m.ConsolideReportsPageModule)
  },
  {
    path: 'consolide-main',
    loadChildren: () => import('./pages/consolide-main/consolide-main.module').then( m => m.ConsolideMainPageModule)
  },
  {
    path: 'consolide-theme',
    loadChildren: () => import('./pages/consolide-theme/consolide-theme.module').then( m => m.ConsolideThemePageModule)
  },
  {
    path: 'maps',
    loadChildren: () => import('./pages/maps/maps.module').then( m => m.MapsPageModule)
  },
  {
    path: 'summary',
    loadChildren: () => import('./pages/summary/summary.module').then( m => m.SummaryPageModule)
  },
  {
    path: 'parkpax',
    loadChildren: () => import('./pages/parkpax/parkpax.module').then( m => m.ParkpaxPageModule)
  },
  {
    path: 'pdf-details',
    loadChildren: () => import('./pages/pdf-details/pdf-details.module').then( m => m.PdfDetailsPageModule)
  },
  {
    path: 'statistcis',
    loadChildren: () => import('./pages/statistcis/statistcis.module').then( m => m.StatistcisPageModule)
  },
  {
    path: 'summary-details',
    loadChildren: () => import('./pages/summary-details/summary-details.module').then( m => m.SummaryDetailsPageModule)
  },
  {
    path: 'avm-home',
    loadChildren: () => import('./pages/avm-home/avm-home.module').then( m => m.AvmHomePageModule)
  },
  {
    path: 'shopping-mall',
    loadChildren: () => import('./pages/shopping-mall/shopping-mall.module').then( m => m.ShoppingMallPageModule)
  },
  {
    path: 'shopping-mall-home',
    loadChildren: () => import('./pages/shopping-mall-home/shopping-mall-home.module').then( m => m.ShoppingMallHomePageModule)
  },
  {
    path: 'partner-hotels',
    loadChildren: () => import('./pages/partner-hotels/partner-hotels.module').then( m => m.PartnerHotelsPageModule)
  },
  {
    path: 'shopping-mall-logo',
    loadChildren: () => import('./pages/shopping-mall-logo/shopping-mall-logo.module').then( m => m.ShoppingMallLogoPageModule)
  },
  {
    path: 'shopping-mall-maps',
    loadChildren: () => import('./pages/shopping-mall-maps/shopping-mall-maps.module').then( m => m.ShoppingMallMapsPageModule)
  },
  {
    path: 'shopping-mall-turnover',
    loadChildren: () => import('./pages/shopping-mall-turnover/shopping-mall-turnover.module').then( m => m.ShoppingMallTurnoverPageModule)
  },
  {
    path: 'summary-home',
    loadChildren: () => import('./pages/summary-home/summary-home.module').then( m => m.SummaryHomePageModule)
  },
  {
    path: 'pdfreports-shopping',
    loadChildren: () => import('./pages/pdfreports-shopping/pdfreports-shopping.module').then( m => m.PdfreportsShoppingPageModule)
  },
  {
    path: 'pdfreports-shopping-details',
    loadChildren: () => import('./pages/pdfreports-shopping-details/pdfreports-shopping-details.module').then( m => m.PdfreportsShoppingDetailsPageModule)
  },
  {
    path: 'tlol',
    loadChildren: () => import('./pages/tlol/tlol.module').then( m => m.TlolPageModule)
  },
  {
    path: 'tlol-detailhc',
    loadChildren: () => import('./pages/tlol-detailhc/tlol-detailhc.module').then( m => m.TlolDetailhcPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
